<template>
  <div class="card">
    <div class="intro">
      <div class="ent-name" v-if="card.institution&&card.institution.name">{{card.institution.name}}</div>
      <div class="position" v-if="card.isManager">管理员</div>
      <div class="time" v-if="card.institution&&card.institution.setmealOverdueTime">有效期至 {{timeFormat(card.institution.setmealOverdueTime)}}</div>
    </div>
  </div>
</template>

<script>
import { timeFormat } from '@/common/utils/function.js'

export default {
  props: {
    card: {
      type: Object
    }
  },
  setup () {
    return {
      timeFormat
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  height: 100px;
  width: 250px;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 0 5px $bg-shadow;
  &:hover {
    opacity: 0.8;
  }
  .card-image {
    width: 100%;
    height: 60%;
  }
  .intro {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .ent-name {
      font-size: $fs14;
      line-height: 24px;
      font-weight: 500;
    }
    .position {
      font-size: $fs10;
      color: $font-blue;
      line-height: 16px;
      width: fit-content;
      padding: 0 3px;
      background: $bg-blue;
      border: 1px solid $border-blue;
    }
    .time {
      font-size: $fs10;
      color: $font-light-gray;
      line-height: 22px;
    }
  }
}
</style>
