<template>
  <div class="banner">
    <div class="image" @click="toWebsite">
      <img src="@/assets/image/website/logo-blue.png" class="logo">
    </div>
    <!-- 页签 -->
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="changeTab">
        <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab, i) in tabs" :key="'tab' + i"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 个人信息 -->
    <el-dropdown @command="handleCommand">
      <span class="personal-information">
        <img :src="user.image" class="photo">
        <span>{{user.name}}</span>
        <i class="el-icon-arrow-down"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import { ElTabs, ElTabPane, ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus'
import { useRouter } from 'vue-router'
import axios from '@/common/utils/axios'
import { ref } from 'vue-demi'
export default {
  components: { ElTabs, ElTabPane, ElDropdown, ElDropdownMenu, ElDropdownItem },
  props: {
    tabs: { // 页签数组
      type: Object
    },
    user: { // 用户信息
      type: Object
    }
  },
  emits: ['changeTab'],
  setup (props, { emit }) {
    const activeName = ref('enterprise')
    const router = useRouter()
    /**
     * 指令列表
     */
    const commandList = {
      logout: () => {
        axios('/admin/user/logout', {})
          .then(res => {
            router.push({ path: '/login' })
          }).catch(_ => { })
      }
    }
    const handleCommand = (command) => {
      commandList[command]()
    }

    /**
     * 切换页签
     */
    const changeTab = tab => {
      emit('changeTab', tab.paneName)
    }

    /**
     * 回到官网页
     */
    const toWebsite = () => {
      router.push('/')
    }

    return {
      activeName,
      changeTab,
      handleCommand,
      toWebsite
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $banner-height;
  width: 100%;
  background: white;
  .image {
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;
    border-right: 1px solid #DCDFE6;
    .logo {
      width: 120px;
    }
  }
  .tabs {
    flex: 1;
    .el-tabs {
      :deep .el-tabs__item {
        font-size: $fs16;
      }
      :deep .el-tabs__nav-wrap::after, :deep .el-tabs__active-bar {
        height: 0;
      }
    }
  }
  .el-breadcrumb :deep {
    .el-breadcrumb__inner {
      color: $font-black;
      font-weight: bold;
    }
  }
  .personal-information {
    cursor: pointer;
    margin-right: 30px;
    display: flex;
    align-items: center;
    * {
      margin-left: 10px;
    }
    .photo {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
