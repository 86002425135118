<template>
  <div class="page">
    <banner :tabs="tabs" :user="user" @changeTab="changeTab"></banner>
    <div class="main">
      <div class="title">{{`企业（${cardList.length}）`}}</div>
      <div class="enterprise">
        <card v-for="(card, i) in cardList" :key="'card' + i" :card="card" @click="choose(card.id, card.isManager)"></card>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from 'vue-demi'
import { useRouter } from 'vue-router'
import banner from './components/banner.vue'
import card from './components/card.vue'
import axios from '@/common/utils/axios'

export default {
  components: { banner, card },
  setup () {
    const tabs = reactive([
      { label: '选择企业', name: 'enterprise' }
    ])
    const user = reactive({})
    const cardList = reactive([])

    const router = useRouter()

    /**
     * 选择企业
     */
    const choose = (id, isManager) => {
      router.push({
        path: '/home',
        query: {
          cid: id,
          isManagement: '0',
          isManager: isManager ? '1' : '0'
        }
      })
    }

    /**
     * 前往运营后台
     */
    const toManagement = () => {
      router.push({
        path: '/home',
        query: {
          isManagement: '1'
        }
      })
    }

    /**
     * 切换页签
     */
    const changeTab = tab => {
      if (tab === 'manage') {
        toManagement()
      }
    }

    /**
     * 企业列表查询接口
     */
    const loadEnterprise = () => {
      axios('/admin/card/list', {}).then(res => {
        if (res.manager && res.manager.isMaster) {
          tabs.push({ label: '运营后台', name: 'manage' })
        }
        user.id = res.member ? res.member.id : ''
        user.name = res.member ? res.member.name : ''
        user.image = res.member ? res.member.image : ''
        res.cardList.forEach(card => {
          cardList.push(card)
        })
      }).catch(_ => { })
    }

    /**
     * 调用企业列表接口
     */
    onMounted(() => {
      loadEnterprise()
    })

    return {
      tabs,
      user,
      cardList,
      changeTab,
      choose
    }
  }

}
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  width: 100%;
  min-width: 600px;
  background: $bg-gray;

  .main {
    padding-top: $banner-height;
    background: $bg-gray;
    .title {
      font-size: $fs16;
      font-weight: 500;
      padding: 25px 45px 10px 45px;
    }
    .enterprise {
      margin-left: 30px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
