function timeFormat (time, separator = '-') {
  const timestamp = new Date(time)
  const year = timestamp.getFullYear()
  let month = timestamp.getMonth()
  month = month < 10 ? '0' + month : month
  let day = timestamp.getDate()
  day = day < 10 ? '0' + day : day
  return year + separator + month + separator + day
}

export { timeFormat }
